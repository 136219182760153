import useModal, { EModalTypes } from "@/hooks/useModal"
import { FC } from "react"
import Button from "@/components/button"
import styles from "@/components/styles/modal.module.css"
import chargeStyles from "@/components/styles/chargeModal.module.css"

import Img, { appendHost } from "../img"
import u, { EVIPtype } from "@/util"

const ChargeModal: FC = () => {
    const [modalData] = useModal()
    
    return (
        modalData?.show && modalData?.id ===  EModalTypes.CHARGE && <div className={styles.common}>
        <div className={`${styles.body} cls_common`}>
            <div onClick={() => u.formUser(EVIPtype.BUY, modalData?.data?.data?.data)} className={styles.close}>X</div>
            <div className={styles.content}>
                <div className={`${chargeStyles.title} mt20`}>您的会员已过期</div>
                <div className="mt20">
                    <Img  src={appendHost("/images/dialog/gold.png")} height={50} width={50} />
                </div>
                <div className={`${chargeStyles.inner_title} mt20`}>你将失去VIP会员功能</div>
                <div className={`fz12 mt20`}>充值之后，请重新登录领取VIP</div>
                <div className={`mt20`}>
                    <Button event={() => { u.formUser(EVIPtype.BUY, modalData?.data?.data?.data)}} cls={`btn_sty2 ${chargeStyles.charge_btn}`}>续费猫咪VIP</Button>
                </div>
            </div>
        </div>
    </div>
    )
}
export default ChargeModal