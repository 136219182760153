import u, { ELocalKey } from "@/util";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BehaviorSubject } from "rxjs";
// import * as gl from "../const/gl.const"
import dlist from "../const/defaultList.const"
import { PROD_SITE } from "@/const/prod.const";

export const confBase$ = new BehaviorSubject({});

const useConfBase = () => {
    const [state, setState] = useState<any>();

    const localData = useMemo(() => {
        try {
            const __data = localStorage.getItem(ELocalKey._BASE);
            let val = JSON.parse(u.decrypt(__data))
            return val
        } catch (error) {
            return {}
        }
    }, [state])

    useEffect(() => {
        const subscription = confBase$.subscribe(setState);
        return () => subscription.unsubscribe();
    }, []);

    const updateState = (val: any) => {
        console.log('updateState', val);
        localStorage.setItem(ELocalKey._BASE, u.encrypt(JSON.stringify(val.data)))
        confBase$.next(val.data)
    };

    /**
     * @description 获取默认数据，当接口获取不了或者被屏蔽
     * @type 1 => 下载， 2 => 商品，3 => 轮播图， 4 => 其余的默认数据(mmqixia, teshezhuanqu等等)；
     */
    const getDefaultData = useCallback((type: number) => {
        const site = PROD_SITE;
        const _l = { ...localData }
        const _row = map[type];
        return new Promise((resolve) => {
            if (_row.directRead) {
                return resolve(_row.data)
            }
            var __l = _l.static_host;
            if (__l) {
                __l = `${__l.slice(0, __l.length - 1)}/${site}`
            }
            if (_row.cacheKey) {
                try {
                    const _encryptCacheValue = localStorage.getItem(_row.cacheKey);
                    if (_encryptCacheValue) {
                        const _cacheValue = JSON.parse(u.decrypt(_encryptCacheValue));
                        return resolve(_cacheValue)
                    }
                } catch (e) { }
            }
            if (__l && _l[_row.key]) {
                u.fetchData(`${__l}${_l[_row.key]}`).then((res) => {
                    let _res = res;
                    try {
                        if (_res) {
                            _res = JSON.parse(res);
                            if (_row.cacheKey) {
                                localStorage.setItem(_row.cacheKey, u.encrypt(res))
                            }
                        } else {
                            _res = _row.data
                        }
                    } catch {
                        _res = _row.data
                    }
                    resolve(_res)
                });
            }
        })
    }, [localData])

    return useMemo(() =>
        ([localData, updateState, getDefaultData]) as [any, (val: any) => void, (type: number) => Promise<any>], [localData, updateState, getDefaultData]);
}
export default useConfBase;

const bannerList = [
    { "img": "https:\/\/uu6522uu.com\/a38b945570134c5c94b9abeaf0e7bd91.gif", "url": "https:\/\/cc77725.com:15588", "title": "4117\u4e00\u53f7\u4f4d\u7f6e.", "type": 2 },
    { "img": "https:\/\/uu6689uu.com\/1754eca535ec4ea2b5ca03d2c0047743.gif", "url": "https:\/\/85889s.com:8955", "title": "494\u4e8c\u53f7\u4f4d\u7f6e", "type": 2 },
    { "img": "https:\/\/aaaaa366.com\/6b392ca8139b4ebdba6cc183222a84db.gif", "url": "https:\/\/6671t.vip:10022", "title": "653\u4e09\u53f7\u4f4d\u7f6e", "type": 1 }
]
const map = {
    1: {
        key: "mm",
        directRead: false,
        data: {
            "maomiapk": {
                "site": 1,
                "name": "maomiapk",
                "url": "https://ywc0.top"
            }
        },
        cacheKey: ELocalKey._MM,
    },
    2: {
        key: "goods",
        directRead: false,
        data: [],
        // {"id":4,"title":"\u7ec8\u8eabVIP","thumb":"","cost":"200.00","old_cost":"5888.00","type":1,"activity":1},{"id":1,"title":"VIP\u6708\u5361","thumb":"","cost":"100.00","old_cost":"150.00","type":1,"activity":0},{"id":2,"title":"VIP\u5b63\u5361","thumb":"","cost":"200.00","old_cost":"450.00","type":1,"activity":0},{"id":3,"title":"VIP\u5e74\u5361","thumb":"","cost":"1200.00","old_cost":"1800.00","type":1,"activity":0} 
    },
    3: {
        key: "banner",
        directRead: false,
        data: [] //bannerList
    },
    4: {
        key: "box",
        directRead: false,
        data: dlist,
        cacheKey: ELocalKey._FORMAT,
    },
} as any
